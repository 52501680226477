<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/1.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">Merhaba, ben</h3>
            </div>
            <div class="name">
              <h3>Enes Subaşı</h3>
            </div>
            <div class="job">
              <p>
                <span class="purpleText">Kreatif Tasarımcı</span>
                ve <span class="greenText">Yazılım / Web Geliştiriciyim</span>
              </p>
            </div>
            <div class="text">
              <p>
                Bağlılıktan kurtulmanız için eşsiz bir fırsat sunuyorum:<br> <span class="orangeText">Tek bir geliştirici</span> ile <span class="purpleText">onlarca farklı dil ve metodoloji</span>
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#about"><span>Hakkımda</span></a>
              </div>
              <div class="social">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/enes_sub"><i class="icon-instagram-3"></i></a>
                  </li>
                  <li>
                    <a href="https://discord.gg/4dcGvT5eQ6"><i class="icon-discord"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/muhammed-enes-suba%C5%9F%C4%B1-07b685157/"><i class="icon-linkedin-1"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/slider/avatar.png" alt="" />
              <span class="skills vuejs anim_moveBottom" v-html="vuejs" style="background: linear-gradient(0deg, rgba(187, 200, 214, 0.35) 0%, rgba(187, 200, 214, 0.35) 35%, rgba(65,184,131,0.15) 100%);"></span>
              <span class="skills photoshop anim_moveBottom" v-html="ps"></span>
              <span class="skills java anim_moveBottom" v-html="java"></span>
              <span class="skills react anim_moveBottom" v-html="react"></span>
              <span class="skills xd anim_moveBottom" v-html="xd"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { xd, react, java, vuejs, ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomeComponent",
  data() {
    return { xd, react, java, vuejs, ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
