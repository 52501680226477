<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_process" id="process">
      <div class="container">
        <div class="list" >
          <ul>
            <li class="wow fadeInUp" data-wow-duration="1s">
              <div class="list_inner">
                <div class="icon" >
                  <span v-html="dark ? pixelPerfectDark : pixelPerfectLight">
                  </span>
                </div>
                <div class="title">
                  <h3>Yazılım ve Web Geliştirme</h3>
                </div>
                <div class="text">
                  <p>
                    Modern çözümler sunan bir yazılım geliştirme uzmanıyım. Projelerinizi hayata geçirirken en yeni teknolojileri ve metodolojileri kullanıyorum. Bunun yanında dinamik ve etkileşimli web siteleri geliştirerek, online varlığınızı güçlendiriyorum. Responsive tasarım, SEO uyumu ve optimize hız ile oluşturduğum web sitelerim, işletmenizin dijital dünyada öne çıkmasını sağlar.
                  </p>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner">
                <div class="icon">
                  <span v-html="dark ? highQualityDark : highQualityLight">
                  </span>
                </div>
                <div class="title">
                  <h3>Tasarım</h3>
                </div>
                <div class="text">
                  <p>
                    Kullanıcı deneyimini ön planda tutan tasarım anlayışım ile markanızın kimliğini yansıtan, estetik ve fonksiyonel tasarımlar oluşturuyorum. Her proje için özgün ve etkileyici tasarım yaklaşımları geliştirerek, kullanıcıların dikkatini çekmeyi ve marka sadakatini artırmayı hedefliyorum.
                  </p>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div class="list_inner">
                <div class="icon">
                  <span v-html="dark ? awesomeIdeaDark : awesomeIdeaLight">
                  </span>
                </div>
                <div class="title">
                  <h3>AI & Otomasyon</h3>
                </div>
                <div class="text">
                  <p>
                    Yapay Zeka ve Otomasyon çözümleriyle, işlerinizi akıllı ve verimli hale getiriyorum. AI tabanlı analizler, veri işleme ve otomatik sistem entegrasyonları ile iş süreçlerinizi optimize ederek zaman ve maliyet tasarrufu sağlıyorum. İleri düzey AI algoritmalarını kullanarak, iş akışlarınızı dönüştürüyor ve dijital dönüşüm yolculuğunuzda size destek oluyorum.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  awesomeIdeaDark,
  awesomeIdeaLight,
  highQualityDark,
  highQualityLight,
  pixelPerfectDark,
  pixelPerfectLight,
} from "@/svg";
export default {
  name: "ProcessComponent",
  data() {
    return {
      pixelPerfectLight,
      highQualityDark,
      awesomeIdeaDark,
      pixelPerfectDark,
      awesomeIdeaLight,
      highQualityLight,
    };
  },
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
