<template>
  <div class="dizme_tm_section" id="contact">
    <div class="dizme_tm_contact">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>İletişime Geçin</span>
          <h3>Fikirlerinizi birlikte hayata geçirelim</h3>
          <p>
            Hafta içi her gün ve cumartesi günü <br> her iletişim talebine yarım saat içinde dönüş yapılır.
          </p>
        </div>
        <div class="contact_inner" style="justify-content: center; align-items: center; display: flex;">
          <div class="left wow fadeInLeft" data-wow-duration="1s" style="justify-content: center; align-items: center; display: flex;">
            <ul>
              <li>
                <div class="list_inner">
                  <div class="icon orangeBackground">
                    <i class="icon-instagram-3 orangeText"></i>
                  </div>
                  <div class="short">
                    <h3>Instagram</h3>
                    <span><a href="https://www.instagram.com/enes_sub">/enes_sub</a></span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon greenBackground">
                    <i class="icon-mail-1 greenText"></i>
                  </div>
                  <div class="short">
                    <h3>Email</h3>
                    <span><a href="mailto:info@enessubasi.com">info@enessubasi.com</a></span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon purpleBackground">
                    <i class="icon-discord"></i>
                  </div>
                  <div class="short">
                    <h3>Discord</h3>
                    <span><a href="https://www.discord.gg/4dcGvT5eQ6">discord.gg/4dcGvT5eQ6</a></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactComponent",
  components: {},
};
</script>
