<template>
  <div class="dizme_tm_section" id="skill">
    <div class="dizme_tm_skills">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div
              class="dizme_tm_main_title wow fadeInUp"
              data-wow-duration="1s"
              data-align="left"
              id="skills_text"
            >
              <span>Kodları Hayata Geçirin</span>
              <h3>JavaScript, Front-End ve Java'da İşlevselliği Yaratıcılıkla Birleştirme</h3>
              <p>
                Herhangi bir dile bağlı kalmadan tek bir geliştirici ile çalışmak için siz de harekete geçin. Size yalnızca tasarımda değil bu tasarımı hayata geçirecek iki önemli unsuru da beraberinde sunuyorum: <br> <strong>Web sitesi geliştirme ve yazılım geliştirme</strong>
              </p>
            </div>
            <div class="dodo_progress wow fadeInUp" data-wow-duration="1s">
              <div
                class="progress_inner skillsInner___"
                data-value="85"
                data-color="#f75023"
              >
                <span
                  ><span class="label">Vue.js, Node.js, React.js ve dahası...</span
                  ><span class="number">85%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="95"
                data-color="#1cbe59"
              >
                <span
                  ><span class="label">Java, Python, Kotlin ve dahası...</span
                  ><span class="number">95%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="90"
                data-color="#8067f0"
              >
                <span
                  ><span class="label">RPA & AI uzmanlığı</span
                  ><span class="number">90%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="80"
                data-color="#e48ced"
              >
                <span
                  ><span class="label">Adobe DW / XD </span
                  ><span class="number">80%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img :src="`/img/skills/1.png`" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillComponent",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
