<template>
  <div class="dizme_tm_section" id="about">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/${dark ? 2 : 1}.jpg`" alt="" />
              <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="13"></VueJsCounter>
                  </h3>
                  <span class="name">Yıllık<br />Başarı</span>
                </div>
              </div>
              <div class="numbers project">
                <div class="wrapper">
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="317"></VueJsCounter>
                  </h3>
                  <span class="name">Tamamlanmış<br />Proje</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <span>Ben bir tasarımcı ve geliştiriciyim</span>
              <h3>Hayal ettiğiniz her şeyi gerçeğe çevirebilirim</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                Ben bir tasraımcı ve yazılım / web gelişiriciyim. Yaptığım işe karşı
                çok tutkuluyum. Profesyonel olarak 13 yıllık tecrübemle
                web geliştirici olarak bilgi ve beceriler edindim.
                Projenizi başarıya ulaştırmak için gerekli her adım için
                tartışma, işbirliği ve sonrasında tasarım sürecine kadar yanınızdayım.
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="#contact"><span>Benimle çalış</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  components: { VueJsCounter },
  props: {
    dark: { type: Boolean },
  },
};
</script>
