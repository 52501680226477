<template>
  <div class="dizme_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <a href="#"
            ><img :src="`img/logo/logo.png`" alt=""
          /></a> <a href="#" style="text-decoration: none;"><span class="logo-text">Enes Subaşı</span></a>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current"><a href="#home">Başlangıç</a></li>
            <li><a href="#process">Neden Ben?</a></li>
            <li><a href="#about">Hakkımda</a></li>
            <li><a href="#skill">Becerilerim</a></li>
            <li class="download_cv">
              <a href="#contact"><span>Bana ulaş</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  props: {
    dark: { type: Boolean },
  },
};
</script>
